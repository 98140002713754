a {
  color: $text-color;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

a {
  &:hover,
  &:focus {
    .btn--link {
      background-color: $secondary-color;
    }

    .link {
      color: $secondary-color;
    }
  }
}

.link {
  color: $primary-color;
  font-weight: 700;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: $primary-color;
    text-decoration: underline;
  }

  &.link--simple {
    font-weight: 400;
    color: $text-color;
    &:hover, &:focus, &:active {
      color: $primary-color;
      text-decoration: none;
    }
  }
}

.link--secondary {
  color: $secondary-color;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: $primary-color;
    // text-decoration: underline;
  }

}

.link--secondary-dark {
  color: $secondary-color-dark;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: $primary-color;
    // text-decoration: underline;
  }

}

// .link--headlings {
//   color: $headlings-color;
//   text-decoration: none;

//   &:hover, &:focus, &:active {
//     color: $primary-color;
//     // text-decoration: underline;
//   }

// }

.link--underline {
  text-decoration: underline;
  text-underline-position: 1.5px;

  &:hover {
    text-decoration: none;
  }
}
