.inputImage {
	position: relative;
	width: 100%;
	height: rem(261);

	border: $border;
	background-color: $body-bg;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	cursor: pointer;

	.icon {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
}