

// Helpers
// ==================================================

.flex-equal {
  flex: 1;
}

.p-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.screen {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

// .no-scroll {
//   margin: 0;
//   height: 100vh;
//   overflow: hidden;
// }

.no-events {
  pointer-events: none !important;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-grey {
  background-color: $grey;
}

.bg-grey-semi-dark {
  background-color: $grey-semi-dark;
}

.bg-body {
  background-color: $body-bg;
}


.bg-grey-dark {
  background-color: $grey-dark;
}

.borderRadius,
.rounded {
  border-radius: $borderRadius;
  overflow: hidden;
}

.modalHidden {
  transform-origin: center !important;
  transform: translateY(-100%) !important;
  transition: $transition !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

.modalShowed {
  transform: translateY(0) !important;
  transition: $transition !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

// Video
.greyToWhiteFilter{
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'brightness\'><feColorMatrix type=\'matrix\' values=\'1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0\'/></filter></svg>#brightness"); /* Firefox 3.5+ */
    filter: brightness(108.5%); /* Chrome 19+ & Safari 6+ */
}

.lh-1 {
  line-height: 1;
}

.lh-125 {
  line-height: 1.25 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.z1 {
  position: relative;
  z-index: 1;
}

.ls-thin {
  letter-spacing: rem(-7);
}

.vh-100 {
  height: 100vh;
}

.vh-100-min {
  min-height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid #D2D2D2;
}

.no-border {
  border: none !important;
  outline: none !important;
}
.no-background {
  background: none !important;
}

.margin-centered {
  margin: 0 auto !important;
}