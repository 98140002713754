.nav__lists {
	.header--vertical & {
		
	}
}

.nav__item {
	.header--vertical & {
		a {
			height: rem(42);
			margin: rem(3) 0;
			padding: 0 rem(26) 0 rem(28);

			border-right: 3px solid transparent;
			transition: $transition;

			.icon {
				width: rem(24)
			}

			span {
				display: block;
				width: 0;
				opacity: 0;

				overflow: hidden;

				color: $grey-nav;

				transition: $transition;
				
			}

			&:hover {
				background: #F4F7FF;
				border-color: #3056D3;				
			}
		}
	}

	.header--vertical:hover & {
		a {
			width: rem(246);
			span {
				width: auto;
				margin-left: rem(20);
				opacity: 1;
			}
		}
	}
}

.nav__item--separator {
	position: relative;
	margin-top: rem(10);
	&:before {
		content: '';
		position: absolute;		

		top: rem(-5);
		left: 50%;
		transform: translateX(-50%);

		width: rem(63);
		height: 1px;
		background: #E7E7E7;

		transition: $transition;

		.header--vertical:hover & {
			width: rem(200);
		}

	}
}