.header {
  // margin-top: rem(7);
  // height: rem(58);

  background: $body-white;
}

.header--vertical {
  position: fixed;
  top: 0;
  left: 0;
  width: rem(80);
  height: 100vh;

  overflow: hidden;

  box-shadow: 0 rem(1) rem(4) rgba(0, 0, 0, 0.12);

  transition: $transition;

  z-index: 5;

  &:hover {
    width: rem(246);
  }
}

.header--horizontal {
  .page--wide & {
    .container {
      @extend .container--wide;
    }
  }
}

.header__logo {
  display: block;
  width: rem(60);
  height: rem(60);
  margin-left: rem(10);  

  position: relative;


  .logo {
    height: rem(60);
    width: auto;

    transition: $transition;

    .header--vertical:hover & {
      opacity: 0;
    }
  }

  .logo--full {
    position: absolute;
    top: rem(5);
    left: 0;

    z-index: 1;    

    opacity: 0;

    transition: $transition;

    height: auto;
    width: rem(220);

    .header--vertical:hover & {
      opacity: 1;
    }
  }  
}

.notify,
.header__notify {
  height: rem(46);
  width: rem(46);
  border-radius: rem(8);

  border: 1px solid #E7E7E7;
  background: #F4F7FF;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background-color: darken(#F4F7FF, 2%);
  }

  .notify__count {
    position: absolute;
    width: rem(19);
    height: rem(19);
    color: $text-color-inverse;

    background-color: #3056D3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    top: rem(2);
    right: rem(2);
    transform: translate(50%, -50%);
  }

  .notify__icon {
    &:after {
      content: '';
      position: absolute;
      width: rem(8);
      height: rem(8);
      border-radius: 50%;
      background: $red;
      border: 1px solid $text-color-inverse;

      top: rem(-2);
      left: 55%;
    }
  }
}

