.layout {
	// display: flex;
	// height: 100vh;
	// overflow: hidden;	

	max-width: 1920px;
	// max-width: rem(1200);
}

.layout__nav {
	width: rem(312);
	// background: blue;
	padding: rem(85) rem(67) rem(100) rem(67);

	top: 0;
	left: 0;
	position: fixed;
	height: 100vh;


}

.layout__content {
	width: calc(100% - #{rem(312)});
	// background: green;
	padding-right: rem(100);
	margin-left: rem(312);

	// overflow: auto;

	.page--wide & {
		width: calc(100% - #{rem(100)});
		padding-right: rem(100);
		margin-left: rem(100);
	}
}

