// // select
select.form-control {
  text-align: left;
  appearance: none;

  position: relative;

  &:not(.select2) {
    background: url(../images/icons/ico-chevron-down.svg);
    background-position: center right 1rem;
    background-size: rem(14) auto;
    background-repeat: no-repeat;
  }
}

.select {

  position: relative;

  // arrow
  &:before {
    content: '';
    
    position: absolute;
    top: 50%;
    right: rem(15) !important;
    transform: translateY(-50%);

    pointer-events: none;
		background: url(../images/icons/ico-chevron-down.svg);
		background-size: 100% 100%;
    

		width: rem(14);
		height: rem(8);
  }

  select {

    text-align: left;
    appearance: none;
    outline: 0;
    cursor: pointer;

    &:invalid {
      color: $primary-color !important;
    }

    // @include media-breakpoint-down(xs) {
    //   font-size: 14px;
    // }
  }

  /* CAUTION: IE hackery ahead */
  select::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  option {
    // @extend .text--semi-large;
    // font-family: Arial, Helvetica, sans-serif;
    // font-weight: normal;
    // color: $text-color;
    // &:disabled {
    //   color: $primary-color !important;
    // }
  }
}


// Select 2
select.form-control.select2 {
  opacity: 0;
} 

.select2 {

  width: 100% !important;

  .select2-selection {
    @extend .form-control;

    display: flex;
    align-items: center;

    padding-top: rem(3);
    min-height: rem(52);
    height: auto !important;

    background: url(../images/icons/ico-chevron-down.svg);
    background-position: center right 1rem;
    background-size: 0.875rem auto;
    background-repeat: no-repeat;
  }

  .select2-selection__rendered {
    margin-bottom: 0;
  }

  .select2-selection__choice {
    margin: 0 rem(3) rem(3) 0 !important;    
  }
  
  .select2-selection__choice__display {
    position: relative;
    top: rem(-2);
    font-size: .6rem;
  }

  .select2-selection__arrow {
    display: none;
  }
}