.infoBox {
	box-shadow: 0 rem(1) rem(3) rgba(0, 0, 0, 0.08);
	.icon {
		width: rem(40);
	}
}

.infoBox--green {
	background: $green-light;
}

.infoBox--yellow {
	background: $yellow-light;
}

.infoBox--blue {
	background: $blue-dark;
}

.infoBox--lightblue {
	background: $blue-light;
}

.infoBox--gold {
	background: #8a6d3b;
}

.infoBox--red {
	background: $red-light;
}
