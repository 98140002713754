.nav-tabs {
	background: $body-white;
	border-radius: $borderRadiusBig;
	border: 1px solid #E3E3E3;

	display: inline-flex;

	overflow: hidden;

	.nav-item {
		// flex: 1;

		.nav-link:not(.active) {
			// border-left: 1px solid #C8C8C8;
			border-right: 1px solid #C8C8C8;
		}

		&:first-child {
			// .nav-link {
			// 	border-left: none !important;
			// }
		}
		
		&:last-child {
			.nav-link {
				border-right: none !important;
			}
		}

	}
	.nav-link {
		border: none;
		height: rem(44);
		border-radius: 0 !important;
		line-height: rem(44);
		padding: 0 1rem;

		cursor: pointer;

		&:hover {
			color: $primary-color;
		}

		&.active {
			background-color: $primary-color;
			color: $text-color-inverse;
		}
	}
}

// .tab-content {
// 	border-radius: 0 0 rem(5) rem(5);
// }