.list--no-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list {
}

.list__item {
  margin-bottom: .5rem;
}

.list--inline {
  display: flex;
}

.list--notify {
  .list__item {
    position: relative;
    padding-left: rem(35);
  
    &:before {
      content: '';
      position: absolute;
      width: rem(15);
      height: rem(15);
      top: 50%;
      left: 0;
      transform: translateY(-50%);
  
      background-size: 100% 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list__item--warning {
  &:before {
    background-image: url(../images/icons/ico-info.svg);
  }
}

.list__item--success {
  &:before {
    background-image: url(../images/icons/ico-info-green.svg);
  }
}