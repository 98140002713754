.section {
  .container > .h1:first-child,
  .container > .h2:first-child {
    margin-top: 0;
  }
}

.section--kdo-jsme {
  .square--2,
  .square--4 {
    position: relative;
    top: rem(30);
  }

  .square--5 {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.section--lectors {
  .h2 {
    
    background: #E3FAE3;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-top: -4rem;
  }

  .bio-card {

    position: relative;
    top: rem(15);

    &:nth-of-type(odd) {
      top: rem(-50);
    }

  }
}

.section--login {
  .square--login {
    .square__footer {
      bottom: -1px;
      left: 0;
    }
  }
}

.section--nwl {
  position: relative;
  z-index: 2;
}