.drag-area{

	text-align: center;
  justify-content: center;
  height: rem(230);

  display: flex;
  width: 100%;
  border-radius: $borderRadiusBig;
  background: #ffffff;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  border: $borderDashed;

	input[type=file] {
		display: none;
	}
}
.drag-area.active{
  border-color: $primary-color;
}
.drag-area span{
  // color: #9B9B9B;
  // font-size: 14px;
  // font-weight: 400;
	text-align: center;

	pointer-events: none;
}
.drag-area small{
  margin: 1em 0 1em 0;
	pointer-events: none;
}
.drag-area button {

  cursor: pointer;

  line-height: 28px;
  padding: 0 1rem;
  margin: 0;
}

.drag-area i {
  display: block;
  margin-top: 1em;
  @extend .text--small;
  font-weight: 400;

  pointer-events: none;
}

.upload__label {
  min-height: 3em;
}

.drag-area__notAvailable {

  label {

    cursor: pointer;
  }

  input {
    display: none;
  }

  input:checked + label {
    background: $grey-dark;
    color: $text-color-inverse;
  }

}

.drag-area--horizontal {
  flex-direction: row;
  height: rem(50);
  width: rem(250);
  padding: 0 .5rem;
  justify-content: space-between;

  & + .drag-area-error {
    width: rem(250);
  }
}

// preview image
.uploadedImage {
  width: 100%;
  height: rem(230);

  border-radius: $borderRadiusBig;
  overflow: hidden;

  position: relative;

  border: $border;

  img {
    width: 90%;
    height: auto;

    @extend .p-abs-center;
  }

  .btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
    .galleryItem--date {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: .4rem;
        background: rgba(0,0,0,.4);
        color: #fff;
        font-size: 10px;
        font-weight: 400;
    }
}
