.toggle > input[type="checkbox"] {
  display: none;   
}

.toggle > label {
  cursor: pointer;
  height: 0;
  position: relative; 
  width: rem(40);  

  span {
    position: absolute;
    top: 50%;
    right: -1rem;
    transform: translate(100%, -50%);

    @extend .text--semi-small;

    transition: $transition;

    &:first-child {
      opacity: 1;
    }

    &:last-child {
      opacity: 0;
    }
  }
}

.toggle--big {
  > label {
    width: auto;
    white-space: nowrap;

    span {
      display: block;
      opacity: 1 !important;
      position: static;
      padding-left: 3rem;
      transform: translateY(-50%);
    }

  }
}

.toggle > label::before {
  background: rgba(85, 112, 241, 0.12);
  // box-shadow: inset 0 0 rem(10) rgba(0, 0, 0, 0.5);
  border-radius: rem(8);
  content: '';
  height: rem(12);
  margin-top: rem(-6);
  position: absolute;
  // opacity: 0.3;
  transition: $transition;
  width: rem(40);
  left: 0;
}
.toggle > label::after {
  background: #BBC5CB;
  background-image: url(../images/icons/ico-toggle.svg);
  background-size: rem(12) auto;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: rem(16);
  // box-shadow: 0 0 rem(5) rgba(0, 0, 0, 0.3);
  content: '';
  height: rem(20);
  left: rem(-2);
  margin-top: rem(-8);
  position: absolute;
  top: rem(-2);
  transition: $transition;
  width: rem(20);
}
.toggle > input[type="checkbox"]:checked + label::before {
  background: rgba(85, 112, 241, 0.4);
  // opacity: 0.5;
}
.toggle > input[type="checkbox"]:checked + label::after {
  background: $purple;
  left: rem(21);
  background-image: url(../images/icons/ico-toggle-check.svg);
  background-size: rem(10) auto;
}

.toggle > input[type="checkbox"]:checked + label {
  span {
    &:first-child {
      opacity: 0;
    }

    &:last-child {
      opacity: 1;
    }
  }
}