.scrollTop {
	position: fixed;
	bottom: 1rem;
	right: 1rem;

	background: rgba(0, 0, 0, 0.75);
	background-image: url(../images/icons/ico-chevron-down-white.svg);
	background-repeat: no-repeat;
	background-size: 1rem auto;
	background-position: center top 1.25rem;
	border: 0;
	border-radius: 50%;
	width: rem(46);
	height: rem(46);

	display: block;

	opacity: .5;

	transition: $transition;

	transform: rotate(-180deg);

	&:hover {
		opacity: 1;
	}
}

.scrollTop--down {
	background-color: rgba(0, 0, 0, 0.2);
	background-image: url(../images/icons/ico-chevron-down.svg);
	transform: rotate(0);

	bottom: 4.5rem;
}