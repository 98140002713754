.table {

	td, th {
		@extend .text--semi-small;
	}

	tbody {
		th, td {
			vertical-align: middle;
		}

		tr:hover {
			td,th {
				background: #D4EDFC;
			}
		}
	}

	thead {
		th {
			border: none;
			border-bottom: 1px solid $border-color;
			color: $tertiary-color;
		}
	}

	td {
		border: none;
		border-bottom: 1px solid $border-color;
	}
}

.tableHeadline {
	margin-left: -3rem;
	margin-right: -3rem;

}

.tableHeadline--red {
	background: $red-light;

	&.tableHeadline--dark {
		background: $red;
	}
}

.tableHeadline--green {
	background: #ECFFE9;

	&.tableHeadline--dark {
		background: $green;
	}
}

// icons v2
.table {
	tr {
		.badge {
			padding: 0;
			width: rem(33);
			height: rem(30);

			display: inline-flex;
			align-items: center;
			justify-content: center;

			margin: 0 rem(5) !important;

			&.badge-success.btn-sm,
			&.badge-danger.btn-sm {

				background-size: rem(17) auto;
				background-position: center;
				background-repeat: no-repeat;

				border: none;

				svg {
					display: none;
				}

			}

			// search filter
			&.badge-success.btn-sm {
				background-image: url(../images/icons/ico-search-white.svg);
				margin-bottom: .25rem !important;
				&:hover {
					background-color: darken($green, 10%);
				}
			}

			// refresh /reset filter
			&.badge-danger.btn-sm {
				background-image: url(../images/icons/ico-refresh.svg);
				margin-left: 0 !important;
				&:hover {
					background-color: #a90000;
				}
			}

			// fant awesome icons replacement
			.fa {
				background-size: 100% auto;
				background-repeat: no-repeat;
				background-position: center;

				width: rem(17);
				height: rem(17);

				&:before {
					display: none;
				}
			}

			&.badge--checked {
				margin: 0 auto !important;

				.fa-check {
					background-image: url(../images/icons/ico-check-white.svg);
				}
			}

			&.badge--unchecked {
				margin: 0 auto !important;
				// background: #F2F2F2;

				.fa-cross {
					background-image: url(../images/icons/ico-cross.svg);
				}
			}

			.fa-edit {
				background-image: url(../images/icons/ico-edit.svg);
			}

			.fa-trash {
				background-image: url(../images/icons/ico-trash.svg);
			}

			.fa-key {
				background-image: url(../images/icons/ico-eye.svg);
			}

			.fa-info-circle {
				background-image: url(../images/icons/ico-info.svg);
			}
		}
	}

	th[data-dg-col=active] {
		select.form-control {
			width: rem(100);
		}
	}

	td[data-dg-col=active] {
		text-align: center;
	}
}

.table-sticky {

	$number-of-columns: 32;

	width: calc(#{rem(120) * $number-of-columns});
	table-layout: fixed;

	th, td {
		width: rem(120);
	}

	.stickyCell {
		z-index: 3;
	}

	.stickyCell--1 {
		left: rem(80);
	}

	.stickyCell--2 {
		left: rem(80 + 120 * 1);
	}

	.stickyCell--3 {
		left: rem(80 + 120 * 2);
	}

	.stickyCell--4 {
		left: rem(80 + 120 * 3);
	}

	.stickyCell--5 {
		left: rem(80 + 120 * 4);
	}

	.stickyCell--6 {
		left: rem(80 + 120 * 5);
	}

	.stickyCell--7 {
		left: rem(80 + 120 * 6);
	}

	.stickyCell--8 {
		left: rem(80 + 120 * 7);
	}

	thead {

		th {
			background: $body-bg;
			position: sticky;
			top: 0;
			// border-bottom: 3px solid $border-color !important;
			// box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
			z-index: 2;

			// border fix
			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 3px;
				background: $border-color;
			}

		}

		.stickyCell--8 {
			// border fix
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 3px;
				height: 100%;
				background: $border-color;
			}
		}

	}

	tbody {

		th, td {
			border-left: 1px solid #E0E0E0;
			border-right: 1px solid #E0E0E0;
		}

		.stickyCell {
			position: sticky;
			top: rem(95);
		}

		tr:nth-of-type(odd) .stickyCell {
			background-color: #eaeaea;
		}

		tr:nth-of-type(even) .stickyCell {
			background-color: $body-bg;
		}

		.stickyCell--8 {

			// border fix
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 3px;
				height: 100%;
				background: $border-color;
			}
		}
	}
}
