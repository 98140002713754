.profile {

}

.profile__name {

}

.profile__avatar {
	width: rem(46);
	height: rem(46);
	border-radius: 50%;
	overflow: hidden;
	position: relative;

	background: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;

	@extend .text--large;
	text-transform: uppercase;
	font-weight: 500;
	color: $text-color-inverse;

	img {
		@extend .fill;
		object-fit: cover;
	}
}

.dropdown-toggle::after {
	border: none;
	width: rem(11);
	height: rem(5);
	background: url(../images/icons/ico-chevron-down.svg) no-repeat;
	background-size: 100% 100%;
}

.dropdown-item.active, .dropdown-item:active {
	background-color: $primary-color;
}