h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-bottom: .5rem;
  // color: $headlings-color;
}

// .text--underline {
//   border-bottom: 1px solid $grey;
// }

h1, .h1 {
}

h2, .h2 {
}

h3, .h3 {
}

h4, .h4 {
}

h5, .h5 {
}

h6, .h6 {
}

p {
  margin-bottom: 1rem;
}

.text {
  @extend .text--normal;
  font-size: rem($root-font-size);
  font-weight: $font-weight-normal;
}


.text--font-base {
  font-family: $font-family-base !important;
}

.text--font-headings {
  font-family: $headings-font-family !important;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--italic {
  font-style: italic;
}

.text--bold, b {
  font-weight: 700 !important;
}

.text--strong, strong {
  font-weight: 700 !important;
}

.text--700 {
  font-weight: 700 !important;
}

.text--600 {
  font-weight: 600 !important;
}

.text--500 {
  font-weight: 500 !important;
}

.text--400 {
  font-weight: 400 !important;
}

.text--strike {
  text-decoration: line-through;
}

.text--xxsmall {
  font-size: rem(8) !important;
}

.text--xsmall {
  font-size: rem(10) !important;
}

small,
.text--small {
  font-size: rem(12) !important;
}

.text--semi-small {
  font-size: rem(14) !important;
}

.text--normal,
.text--normal-size {
  font-size: rem($root-font-size) !important;
}

.text--semi-large {
  font-size: rem(18) !important;
}

.text--large {
  font-size: rem(20) !important;
}

// .text--large2 {
//   font-size: rem(24) !important;
// }

// .text--large3 {
//   font-size: rem(28) !important;
// }

.text--xlarge {
  // font-size: $h2-font-size;
  font-size: rem(22) !important;
}

.text--xxlarge {
  // font-size: $h2-font-size;
  font-size: rem(24) !important;
}

.text--xxxlarge {
  // font-size: $h2-font-size;
  font-size: rem(32) !important;
}

// .text--ultra-large {
//   font-size: rem(60) !important;
//   letter-spacing: em(-3, 60);

// }

.text--primary {
  color: $primary-color !important;
}

.text--secondary {
  color: $secondary-color !important;
}

.text--tertiary {
  color: $tertiary-color !important;
}

.text--purple {
  color: $purple !important;
}

.text--blue {
  color: $blue !important;
}

.text--blue-dark {
  color: $blue-dark !important;
}

.text--green {
  color: $green !important;
}

.text--green-dark {
  color: $green-dark !important;
}

.text--yellow {
  color: $yellow !important;
}

.text--error,
.text--red {
  color: $red !important;
}

// .text--secondary-dark {
//   color: $secondary-color-dark !important;
// }


.text--body-color {
  color: $body-color !important;
}

.text--dark {
  color: $text-color-dark !important;
}

.text--grey-nav {
  color: $grey-nav !important;
}

// .text--headlings {
//   color: $headlings-color !important;
// }

// .text--green,
.text--success {
  color: $green;
}

.text--inverse,
.text--white {
  color: #fff !important;
}

.text--dark,
.text--black {
  color: #000;
}

.text--grey-light2 {
  color: $grey-light2 !important;
}

// .text--label {
//   @extend .form-control-label;
//   font-weight: 600 !important;
// }

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(xs) {

  h1, .h1 {
    font-size: rem(24, 14);
  }

  h2, .h2 {
    font-size: rem(20, 14);
  }

  h3, .h3 {
    font-size: rem(18, 14);
  }

  h4, .h4 {
    font-size: rem(16, 14);
  }

  h5, .h5 {
    font-size: rem(14, 14);
  }

  h6, .h6 {
    font-size: rem(12, 14);
  }

}