.checkbox {
  // margin-left: 2px;
}

.form-check {
  padding-left: 0;
}

.form-check-label {

  @extend .text--semi-small;
  position: relative;
  padding-left: rem(46);

  cursor: pointer;
  user-select: none;

  // transition: $transition;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    transition: $transition;
  }

  // box
  &:before {
    width: rem(24);
    height: rem(24);
    background: transparent;
    left: 0;
    top: rem(-4);

    border: 1px solid $border-color;
    border-radius: $borderRadiusBig;
  }

  // check
  &:after {
    content: '';
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);

    transition: $transition;

    width: rem(20);
    height: rem(20);

    background-color: $primary-color;
    background-image: url(../images/icons/ico-check.svg);
    background-size: rem(10) auto;
    background-repeat: no-repeat;
    background-position: center;

    border-radius: rem(6);

    top: rem(8);
    left: rem(12);


  }

  // &:hover {
  //   // color: $primary-color;
  //   &:before {
  //     // border-color: $primary-color !important;
  //     // background-color: $primary-color;
  //   }
  // }

  
  &:hover,
  &:focus {
    &:before {
      border-color: $primary-color-dark !important;
    }
  }
}

.form-check-input {
  // display: none;
  position: absolute;
  left: -99999px;
  opacity: 0;

  &:checked ~ .form-check-label:before {
    // background: $primary-color;
    border-color: $primary-color-dark;
  }

  &:checked ~ .form-check-label:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  &.text-danger ~ .form-check-label:before {
    box-shadow: inset 0 0 0 2px $error-color !important;
  }
}

.form-check.parsley-error {

  .form-check-label {
    &:before {
      border-color: $error-color;
    }
  }

  + .parsley-errors-list {
    @extend .text--semi-small;
    list-style: none;
    margin: 0;
    margin-top: .25em;
    margin-bottom: 1rem;
    padding: 0;
    color: $error-color;

    padding-left: rem(55);

    position: relative;

    &:before {
      content: '';
      position: absolute;

      top: rem(3);
      left: rem(8 + 25);

      width: rem(13.06);
      height: rem(11.31);

      background: url(../images/icons/ico-alert.svg);
      background-size: 100% 100%;
    }
  }
}

.checkbox--small {
  
  .form-check-label {
    padding-left: rem(23);

    &:before {
      width: rem(18);
      height: rem(18);
      border-radius: rem(4);

      top: rem(-1);

    }

    &:after {
      width: rem(14);
      height: rem(14);
      border-radius: rem(3);
      background-size: rem(8) auto;

      top: rem(8);
      left: rem(9);
    }
  }
}