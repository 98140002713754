.tag {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: rem(5) rem(5);
	border-radius: $borderRadius;

	color: $text-color-inverse;
	background: $border-color;
	text-transform: uppercase;
	margin: 0 rem(2);
	height: rem(30);

	border: none;
	outline: none;

	&:hover {
		color: $text-color-inverse;
	}

	// notification count
	.notification {
		position: absolute;
		width: rem(18);
		height: rem(18);
		line-height: rem(18);
		border-radius: 50%;
		text-align: center;

		background: $red;

		color: $text-color-inverse;

		top: 0;
		right: 0;

		transform: translate(30%, -30%);

	}

	&.contains-output-documents {
		cursor: pointer;

		// red dot
		&:before {
			content: '';
			position: absolute;
			width: rem(5);
			height: rem(5);
			background: $red;
			border-radius: 50%;
			top: rem(2);
			right: rem(2);
			opacity: .75;
			transition: $transition;

			transform: scale(1.25);
		}

		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}
}

.tag--purple,
.tag--primary {
	background: $primary-color;
}

a.tag--purple:hover,
button.tag--purple:hover,
a.tag--primary:hover,
button.tag--primary:hover {
	background-color: darken($primary-color, 3%);
}

.tag--green,
.tag--secondary {
	background: $secondary-color;
}

a.tag--green:hover,
button.tag--green:hover,
a.tag--secondary:hover,
button.tag--secondary:hover {
	background-color: darken($secondary-color, 3%);
}

.tag--blue,
.tag--tertiary {
	background: $tertiary-color;	
}

a.tag--blue:hover,
button.tag--blue:hover,
a.tag--tertiary:hover,
button.tag--tertiary:hover {
	background-color: darken($tertiary-color, 3%);
}

.tag--red,
.tag--error {
	background: $red;
}

a.tag--red:hover,
button.tag--red:hover,
a.tag--error:hover,
button.tag--error:hover {
	background-color: darken($red, 3%);
}

.tag--icon {
	width: rem(33);
	background-repeat: no-repeat;
	background-size: rem(17) auto;
	background-position: center;
}

.tag--info {
	background-image: url(../images/icons/ico-info.svg);
}

.tag--edit {
	background-image: url(../images/icons/ico-edit.svg);
}

.tag--archive {
	background-image: url(../images/icons/ico-archive.svg);
}

.tag--trash {
	background-image: url(../images/icons/ico-trash.svg);
}

.tag--refresh {
	background-image: url(../images/icons/ico-refresh.svg);
}