.note {

	position: relative;

	background: #f1f0ef;
	padding: rem(13) rem(60) rem(13) rem(120);

	// icon
	&:before {
		content: '!';

		@extend .text--large;

		position: absolute;
		top: rem(18);
		left: rem(75);

		width: rem(25);
		height: rem(25);

		display: flex;
		align-items: center;
		justify-content: center;

		font-weight: 500;

		border: 2px solid $text-color;

	}
}

.note--success {
	background: $primary-color;

	&:before {
		content: '✓';
		color: $text-color-inverse;
		border-color: $text-color-inverse;
		top: rem(14);
	}
}