.btn {
	@extend .text--semi-small;
	border: none;
	position: relative;
	height: rem(52);
	line-height: rem(52);
	padding: 0 1rem;
	min-width: rem(160);
	font-weight: 600;
	background-color: $primary-color;
	box-shadow: none !important;

	white-space: nowrap;

	color: $text-color-inverse;

	border-radius: $borderRadius;

	display: inline-block;

	text-decoration: none;

	transition:  $transition;

	font-weight: 600;

	&:hover {
		color: $text-color-inverse;
		background-color: darken($primary-color, 5%);
	}
}

.btn--bordered {
	background: $body-white;

	border: 2px solid $primary-color !important;
	color: $primary-color;

	line-height: rem(50);


}

// .btn.btn--danger {
//     background-color: #BD2130;
// }

// a.btn:not(.btn--add),
// input.btn:not(.btn--add),
// button.btn:not(.btn--add) {
// 	&:hover,
// 	&:focus {
// 		text-decoration: none;
// 		color: $text-color-inverse;
// 		background-color: darken($primary-color, 5%);
// 	}
// }

// .btn--big:not(.btn--add) {
// 	height: rem(60);
// 	line-height: rem(58);

// 	@extend .text--large;
// }

.btn--small {
	height: rem(30);
	line-height: rem(28);
	min-width: auto;
	padding: 0 1rem;
	font-weight: 400;

	@extend .text--small;
}

.btn--grey {
	background: #F4F4F4;
	color: #999999;

	&:hover {
		background: $grey-dark;
		color: $text-color-inverse;
	}
}

.btn--danger {
	background-color: $red;
	&:hover {
		background-color: darken($red, 3%);
	}
}

.btn--green {
	&.btn--bordered {
		border-color: $green-dark !important;
		color: $green-dark;

		&:hover {
			background: $green-dark;
			color: $text-color-inverse;
		}
	}
}

.btn--add {
	background: $green;

	height: rem(40);
	line-height: 1;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: auto;

	&:hover {
		background-color: $green-dark;
	}
}

// .btn--xsmall:not(.btn--add) {
// 	height: rem(26);
// 	line-height: rem(24);

// 	@extend .text--small;
// }

// .btn--bordered:not(.btn--add) {
// 	border: rem(3) solid $primary-color;
// 	background-color: transparent;
// 	color: $primary-color;
// 	&:hover,
// 	&:focus {
// 		border-color: darken($primary-color, 5%);
// 	}
// }

// .btn--secondary:not(.btn--add) {
// 	color: $text-color;
// 	background-color: $secondary-color;

// 	&:hover,
// 	&:focus {
// 		text-decoration: none;
// 		color: $text-color !important;
// 		background-color: darken($secondary-color, 5%) !important;
// 	}

// }

// .btn--add {
// 	padding: 0;
// 	box-shadow: none !important;

// 	.icon {
// 		transition: $transition;
// 	}

// 	&:hover {
// 		.icon {
// 			transform:scale(1.1);
// 		}
// 	}
// }
