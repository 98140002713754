.textarea {
  position: relative;
  width: 100%;
  height: 100%;

  // .wpcf7-form-control-wrap {
  //   position: static !important;
  // }

  .form-control {
    // height: rem(200);
    width: 100%;
    height: rem(160);
    // padding: rem(45);
    // border: $border;

    padding: 1rem;

    @include media-breakpoint-down(md) {
      // padding-left: rem(30);
      // padding-right: rem(30);

      height: rem(150, 20) !important;
    }
  }
}

textarea.form-control {
  // height: rem(200);
  width: 100%;
  height: rem(160);
  // padding: rem(45);
  // border: $border;

  padding: 1rem;

  @include media-breakpoint-down(md) {
    // padding-left: rem(30);
    // padding-right: rem(30);

    height: rem(150, 20) !important;
  }
}