.rule {
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: $border-color;

		left: 50%;
		top: 50%;
		transform: translate(-50%, 0);
	}

	> span {
		position: relative;
		background: $body-bg;
		padding: 0 rem(10);
	}
}