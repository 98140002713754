
// https://gist.github.com/imgarylai/6190960

/*

====== Zoom effect ======

*/
.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}


/*

====== Newspaper effect ======

*/
.mfp-newspaper {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.5s;

    transform: scale(0) rotate(500deg);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.5s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0) rotate(500deg);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}



/*

====== Move-horizontal effect ======

*/
.mfp-move-horizontal {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;

    transform: translateX(-50px);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateX(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: translateX(50px);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}


/*

====== Move-from-top effect ======

*/
.mfp-move-from-top {

  .mfp-content {
   vertical-align:top;
  }

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s;

    transform: translateY(-100px);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.2s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateY(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: translateY(-50px);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}


/*

====== 3d unfold ======

*/
.mfp-3d-unfold {


  .mfp-content {
    perspective: 2000px;
  }

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform-style: preserve-3d;
    transform: rotateY(-60deg);
  }


  &.mfp-bg {
    opacity: 0;
	  transition: all 0.5s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: rotateY(0deg);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: rotateY(60deg);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}




/*

====== Zoom-out effect ======

*/
.mfp-zoom-out {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(1.3);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}



/*

====== "Hinge" close effect ======

*/

@keyframes hinge {
	0% { transform: rotate(0); transform-origin: top left; animation-timing-function: ease-in-out; }
	20%, 60% { transform: rotate(80deg); transform-origin: top left; animation-timing-function: ease-in-out; }
	40% { transform: rotate(60deg); transform-origin: top left; animation-timing-function: ease-in-out; }
	80% { transform: rotate(60deg) translateY(0); opacity: 1; transform-origin: top left; animation-timing-function: ease-in-out; }
	100% { transform: translateY(700px); opacity: 0; }
}

.hinge {
  animation-duration: 1s;
	animation-name: hinge;
}
.mfp-with-fade {
  // before-open state
  .mfp-content,
  &.mfp-bg {
    opacity: 0;
    transition: opacity .5s ease-out;
  }
  // open state
  &.mfp-ready {
    .mfp-content {
     opacity: 1;
    }
    &.mfp-bg {
      opacity: 0.8; // background opacity
    }
  }

  // closed state
  &.mfp-removing {
    &.mfp-bg {
      opacity: 0;
    }
  }
}

.mfp-arrow {
  transition: $transition;

  .mfp-ready & {
    opacity: 1;
  }

  .mfp-removing & {
    opacity: 0;
  }
}