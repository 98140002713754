.logo {
	width: rem(170);
}

.logo--big {
	width: rem(340);
}

.logo--small {
	width: rem(85);
}