.pagination {
	display: flex;
	justify-content: center;
}

.page-item {

	width: rem(24);
	height: rem(24);

	padding: 0;

	border-radius: $borderRadius !important;

	margin: 0 rem(5);

	background: $blue;
	color: $text-color-inverse;
	text-align: center;
	line-height: rem(24);
	cursor: pointer;
	transition: $transition;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	&:hover {
		background: darken($blue, 10%);
		
	}

	&.active {
		background: $green-dark;
		pointer-events: none;
	}

	// prev and next button
	&:first-child,
	&:last-child {
		background: transparent !important;
		border: 1px solid $blue;

		padding-bottom: .2rem;

		.page-link {
			color: $blue !important; 
			font-size: 1.35rem !important;			
		}

		&.disabled {
			border-color: #DCDBDC;
			pointer-events: none;
			.page-link {
				color: #DCDBDC !important;
			}
		}
	}	
}

.page-link {
	@extend .text--small;
	padding: 0;
	display: block;
	background: transparent !important;
	color: $text-color-inverse !important;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;

	width: rem(24);
	height: rem(24);

	box-shadow: none !important;

}